@import '../../../../../scss/theme-bootstrap';

.product-row {
  display: flex;
  border-bottom: 1px solid $color-light-gray;
  padding-top: 24px;
  padding: 24px 0 12px;
  &:first-child {
    padding: 0;
  }
  &:last-child {
    border: none;
    .product-row__info-remove {
      padding: 15px 0 0;
    }
  }
  &__cart-img {
    width: 85px;
    height: 85px;
    display: inline-block;
    img {
      width: inherit;
      height: inherit;
      min-width: 85px;
    }
  }
  &__info {
    padding-#{$ldirection}: 32px;
    width: 100%;
    text-align: $ldirection;
    &-name,
    &-price,
    &-qty,
    &-remove-link {
      display: inline;
    }
    &-price,
    &-remove-link {
      float: $rdirection;
      font-size: 14px;
      line-height: 1;
    }
  }
}
